import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp, FiMail, FiCopy } from "react-icons/fi";
import IM4 from '../../../public/assets/images/montealban/Todas/IM4.png';

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut.'
    },
    /* {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile Development',
        description: 'Desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
    },
    {
        icon: <FiMail />,
        title: 'Email Marketing',
        description: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in.'
    },
    {
        icon: <FiCopy />,
        title: 'Copywriting',
        description: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for.'
    }, */
]

class ServiceTwo extends Component {
    render() {
        let title = 'COCTELERÍA',
            description = '',
            subtitle = '#SOMOSMONTEALBAN';
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                {/* <div className="thumbnail"><img className="w-80" src={IM4} alt="About Images" /></div> */}
                                <div className="container" style={{ height: '400px', overflowX: 'hidden' }}>
                                    <div className="row row--20 align-items-center">

                                        {/* Lista Pollo Mezcal */}
                                        <div className="col-lg-12 col-md-12">
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <div className="section-title">
                                                <h2 className="title">CÓCTEL OAXACA</h2>
                                            </div>
                                            <div className="row" style={{ marginTop: '-20px' }}>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h5 className="title" style={{ color: 'red' }}>COCTEL #1</h5>
                                                        <p style={{ color: '#000000' }}>Batir un poco en licuadora, colocar en copa champañera, adornar con una cereza, escarchar la copa con sal.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h5 style={{ color: 'red' }} className="title">Ingredientes</h5>
                                                        <ul style={{ color: '#000000' }}>
                                                            <li>3/4 copa mezcal</li>
                                                            <li>1 copa de jugo de piña fresca</li>
                                                            <li>1 cucharadita de jarabe</li>
                                                            <li>1 cucharadita de jugo de limón</li>
                                                            <li>1/2 taza de hielo picado</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <span className="subtitle">#CocteleríaMonteAlban</span>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pt--10">
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <div className="section-title">
                                                <h2 className="title">MEZCAL LIBRE</h2>
                                            </div>
                                            <div className="row" style={{ marginTop: '-20px' }}>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h5 className="title" style={{ color: 'red' }}>COCTEL #2</h5>
                                                        <p style={{ color: '#000000' }}>Refresco de cola al gusto y agítese.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h5 style={{ color: 'red' }} className="title">Ingredientes</h5>
                                                        <ul style={{ color: '#000000' }}>
                                                            <li>2 copas de mezcal</li>
                                                            <li>2 cubos de hielo</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <span className="subtitle">#CocteleríaMonteAlban</span>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pt--10">
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <div className="section-title">
                                                <h2 className="title">CÓCTEL DONAJI</h2>
                                            </div>
                                            <div className="row" style={{ marginTop: '-20px' }}>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h5 className="title" style={{ color: 'red' }}>COCTEL #3</h5>
                                                        <p style={{ color: '#000000' }}>Agitar con hielo picado, servir en copa champañera escarchada con sal.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h5 style={{ color: 'red' }} className="title">Ingredientes</h5>
                                                        <ul style={{ color: '#000000' }}>
                                                            <li>1 ¼ copa de mezcal</li>
                                                            <li>4 copas de jugo de naranja</li>
                                                            <li>1/4 de copa de jugo de limón</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <span className="subtitle">#CocteleríaMonteAlban</span>
                                        </div>

                                        <div className="col-lg-12 col-md-12 pt--10">
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />

                                            <div className="row" style={{ marginTop: '-20px' }}>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h3 className="title">GUSANO TRADICIONAL</h3>
                                                        <ul style={{ color: '#000000' }}>
                                                            <li>Mezcal con gusano servido en copa mezcalera</li>
                                                            <li>Limón o naranja en rebanadas</li>
                                                            <li>Sal de gusano</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h3 className="title">GUSANO SUNRISE</h3>
                                                        <ul style={{ color: '#000000' }}>
                                                            <li>1 ½ onza de mezcal con gusano</li>
                                                            <li>1 onza de granadina</li>
                                                            <li>Jugo de naranja</li>
                                                            <li>Hielo</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                            <span className="subtitle">#CocteleríaMonteAlban</span>
                                        </div>

                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map((val, i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
