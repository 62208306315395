import React, { Component } from "react";

class AboutTwo extends Component {
    render() {
        let title = 'About',
            description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--20 align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">#Recetas&Cocteles</span>
                                        <h2 style={{ color: 'white' }} className="title">RECOMENDACIÓN</h2>
                                        <p className="description">Para empezar, hay que servirlo en un "caballito". A la mano debe estar un plato con sal de gusanitos, mezcla del gusano de maguey seco, tostado, molido con chile costeño y sal otro con limones cortados. Se toma una pizquita que se pone en la punta de la lengua y esto es como encender la mecha, de inmediato viene el trago lento y pausado.</p>
                                    </div>
                                    <div className="purchase-btn mt--20 mb--20">
                                        <a className="btn-transparent">RECETA & COCTELES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-70" src="/assets/images/montealban/Todas/IM17.png" alt="About Images" />
                                </div>
                            </div>
                            {/* Lista Pollo Mezcal */}
                            <div className="col-lg-12 col-md-12 pt--50">
                                <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                <div className="section-title">
                                    <span className="subtitle">#RecetaMonteAlban</span>
                                    <h2 style={{ color: 'white' }} className="title">Pollo al Mezcal</h2>
                                </div>
                                <div className="row" style={{ marginTop: '-20px' }}>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            <h5 className="title" style={{ color: 'red' }}>RECETA #1</h5>
                                            <p style={{ color: '#FFFFFF' }}>Se limpia y se lava el pollo. Se parte en piezas y se salpimenta. Se sancocha en la mantequilla derretida con el aceite y se retira de la sartén. La cebolla fileteada se fríe con el ajo en el mismo aceite donde se sancochó el pollo y se retira de la sartén. Ahí mismo se sancochan las papitas, lavadas y peladas con los champiñones. Se retira todo. Se coloca el pollo agregando los demás ingredientes y el agua. Tapar y cocinar a fuego lento por treinta minutos. Se agrega el mezcal y se deja hervir durante cinco minutos.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            <h5 style={{ color: 'red' }} className="title">Ingredientes (Platillo para 6 personas)</h5>
                                            <ul style={{ color: '#FFFFFF' }}>
                                                <li>1 pollo de regular tamaño</li>
                                                <li>1 kilo de papitas cambray</li>
                                                <li>1/4 kilo de champiñones</li>
                                                <li>1 cebolla mediana</li>
                                                <li>2 cucharadas soperas de mantequilla</li>
                                                <li>1 cucharada sopera de aceite de cocina</li>
                                                <li>3 cucharadas soperas de caldo de pollo</li>
                                                <li>2 dientes de ajo pelados y picados</li>
                                                <li>3 ramas de tomillo</li>
                                                <li>2 hojas de laurel</li>
                                                <li>2 pimientas gruesas (o gordas)</li>
                                                <li>3 pimientas delgadas</li>
                                                <li>1 clavo de olor</li>
                                                <li>1 una copa (caballito) de mezcal de puntas</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Lista Pollo Mezcal */}
                            <div className="col-lg-12 col-md-12 pt--50">
                                <hr style={{ backgroundColor: 'red', height: '2px', width: '100%' }} />
                                <div className="section-title">
                                    <span className="subtitle">#RecetaMonteAlban</span>
                                    <h2 style={{ color: 'white' }} className="title">Ensalada Verde a la Oaxaqueña</h2>
                                </div>
                                <div className="row" style={{ marginTop: '-20px' }}>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            <h5 className="title" style={{ color: 'red' }}>RECETA #2</h5>
                                            <p style={{ color: '#FFFFFF' }}>Se lava y desinfecta perfectamente la lechuga y se coloca en una fuente. La cebolla desflemada se pone sobre la lechuga. Se agrega el pepino, se adorna con el aguacate y las hojitas de chepiche. Agregar la vinagreta.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list" style={{ overflowX: 'hidden', height: '245px' }}>
                                            <h5 style={{ color: 'red' }} className="title">Ingredientes (Platillo para 4 personas)</h5>
                                            <ul style={{ color: '#FFFFFF' }}>
                                                <li>1 lechuga romanita en trozoso</li>
                                                <li>3 aguacates maduros en tiras</li>
                                                <li>1 cebolla mediana fileteada</li>
                                                <li>4 ramitas de chepiche o pápalo</li>
                                                <li>2 pepinos cortados en cuadros</li>
                                            </ul>
                                            <h5 style={{ color: 'red' }} className="title">Vinagreta</h5>
                                            <ul style={{ color: '#FFFFFF' }}>
                                                <li>1/2 de vinagre de manzana (o de alguna fruta)</li>
                                                <li>2 cucharadas de mostaza de Dijo</li>
                                                <li>10 pimientas chicas molidas</li>
                                                <li>1 pizca de sal</li>
                                                <li>1 pizca de azúcar</li>
                                                <li>Jugo de limón al gusto</li>
                                                <li>Mezclar todos los ingredientes en la licuadora</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;