import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock, FiUser, FiMessageCircle, FiHeart, FiSun } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
/* import Footer from "../component/footer/Footer"; */
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import fondo from '../../public/assets/images/montealban/montealban.png';
import Clip from '../../public/assets/images/montealban/montealbanagave.mp4';

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Monte Alban || Historia' />
                <HeaderThree headertransparent="header--transparent" colorblack="color--black" homeLink="/" logo="dark" />

                <div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <video style={{ position: 'absolute', width: 'auto', height: 'auto', minWidth: '100%', minHeight: '100%' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                        <source src={Clip} type="video/mp4" />
                    </video>
                </div>

                {/* Start Breadcrump Area */}
                <div style={{ backgroundColor: '#000000' }} className="rn-page-title-area" >
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--40" >
                                    <h2 className="title theme-gradient">EL HOMBRE Y LOS AGAVES</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div style={{ backgroundColor: '#000000' }} className="rn-blog-details pb--40 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">

                                        <p className="mt--10" style={{ color: 'white', textAlign: 'center' }}>
                                            Hace diez mil años comenzó en Mesoamérica la relación del hombre con el maguey, desde que sus antiguos pobladores vivían en forma nómada, hasta su asentamiento en lugares donde, al igual que otros cultivos, domesticaron el agave. A partir de entonces esta planta fue elemento importante de prácticas propiciatorias de lluvia, recolección de cosechas, ceremonias de algunos dioses y ritos de iniciación.
                                        </p>

                                        <p style={{ color: '#daa520', fontWeight: 'bolder', textAlign: 'center' }}>
                                            "La milenaria historia cultural que conforma el México actual radica en su diversidad geográfica y, por ende, en el cultivo del maguey o agave-del griego admirabilis, por su sobrevivencia con escasos niveles de agua. Las zonas semiáridas del territorio nacional están pobladas de estos gigantescos erizos vegetales, donde existen más de un centenar de variedades, cuyos nombres y usos varían de región a región: zapupe, amole, ixtle, pita, magueyón, zapalote, etcétera."
                                        </p>
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'red', color: 'white', width: '100%' }} />
                                        <div className="d-flex flex-wrap mb--15">
                                            <div className="content">
                                                <img style={{ borderRadius: '15px' }} className="w-70" src="/assets/images/montealban/Todas/IM1.png" alt="BLog Images" />
                                            </div>
                                        </div>
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'red', color: 'white', width: '100%' }} />
                                        
                                        <p style={{ color: 'white', textAlign: 'center' }}>
                                            Cuenta la leyenda que en la región mixteca la princesa Mayahuel descubrió en el Popoconaltepec -Monte de la Espuma- la manera de extraer del corazón de la planta llamada meztl un líquido delicioso y enervante. A la llegada de los españoles, el meztl fue rebautizado como maguey.
                                            <br />
                                            El meztl o maguey pertenece a la familia de las agavaceas. Sus hojas grandes, tiesas, radicales, carnosas, de bordes espinosos y con una descollante púa en la punta, están organizadas a modo de roseta piramidal y formadas por un tejido filamentoso con cuya savia se produce una bebida incitadora de alegría y agradable somnolencia.
                                            <br />
                                            Para crecer y madurar adecuadamente, los plantíos del agave mezcalero, espadín o angustifolia haw, requieren de limpieza y cuidado, así como mantenerse libres de maleza, pues fácilmente se plagan o enronchan, aunque lo maravilloso de este vegetal es que hasta sus plagas son aprovechables, según observaron los frailes evangelizadores. Es precisamente un gusano (hypopta agavis) el que ha dado sabor especial y fama al mezcal de Oaxaca.
                                        </p>
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'red', color: 'white', width: '100%' }} />
                                        <div className="d-flex flex-wrap mb--15">
                                            <div className="content">
                                                <img style={{ borderRadius: '15px' }} className="w-70" src="/assets/images/montealban/Todas/IM2.png" alt="BLog Images" />
                                            </div>
                                        </div>
                                        
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'red', color: 'white', width: '100%' }} />
                                        <br />

                                        <div className="d-flex flex-wrap">
                                            <div className="content">
                                                {/* <h4 className="title">Ordered & Unordered Lists.</h4> */}

                                                <ul className="list-style">
                                                    <li style={{ color: '#daa520' }}>A pesar de la apariencia árida y desolada del paisaje donde crece el agave, y de la fácil obtención de otros aguardientes, los mezcaleros oaxaqueños siempre han defendido lo suyo basados en la pureza ritual de su elaboración, transmitida de abuelos a padres y de padres a hijos.</li>
                                                </ul>
                                                {/* <h4 className="title">Ordered & Unordered Lists.</h4> */}
                                                <ul className="list-style">
                                                    <li style={{ color: '#FFFFFF' }}>En la actualidad esta producción local se ha modificado, el desarrollo económico del país ha alentado su fabricación no sólo para el mercado regional, sino nacional e internacional.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br />
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'red', color: 'white', width: '100%' }} />
                                        <br />
                                        <div className="d-flex flex-wrap mb--10 ">
                                            <div className="content">
                                                <img style={{ borderRadius: '15px' }} className="w-70" src="/assets/images/montealban/Todas/IM3.png" alt="BLog Images" />
                                            </div>
                                        </div>
                                        <br />
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'red', color: 'white', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <img style={{width: '100%', paddingTop: '40px', marginBottom: '-10px'}} src="/assets/images/montealban/Todas/todas.png" alt="Blog Images" /> */}
                </div>
                {/* End Blog Details */}

                {/* Start BLog Comment Form  */}
                {/* <div className="blog-comment-form pb--80 ptb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">¿Desea preguntarnos algo?</span>
                                        <h2 className="title">Escribenos a nuestro correo</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Nombre" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comentario"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>ENVIAR COMENTARIO</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;