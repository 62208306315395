import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp, FiMail, FiCopy } from "react-icons/fi";
import IM4 from '../../../public/assets/images/montealban/Todas/IM4.png';

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut.'
    },
    /* {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile Development',
        description: 'Desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
    },
    {
        icon: <FiMail />,
        title: 'Email Marketing',
        description: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in.'
    },
    {
        icon: <FiCopy />,
        title: 'Copywriting',
        description: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for.'
    }, */
]

class ServiceTwo extends Component {
    render() {
        let title = 'FLORACIÓN',
            description = '1.- La raíz, fibrosa y exuberante, se combina con otras más gruesas llamadas rizomas, las cuales corren cerca de la superficie de la tierra, en el momento que éstas llegan a tener contacto con el sol desarrollan una yema formando un hijuelo. Cuando el suelo de alrededor se mantiene limpio crecen más hijuelos.',
            description2 = '2.- Ápice floral, quiote o escapo floral.',
            description3 = '3.- Corona o base de la raíz.',
            description4 = '4.- Ramas de color verde. Entre los meses de enero y febrero crecen casi simétricas. En mayo florecen y se tornan amarillas. A partir de la poda de las flores los cultivadores de agave evitan la formación del fruto. Una vez cortados los pétalos, cada una de las yemas axilares que están en la base de la flor desarrollan un maguey idéntico a la madre. Uno a uno estos bebés se cortan del quiote y se siembran en un invernadero para que hagan raíces. A los tres años estarán listos para ser trasplantados al terreno definitivo. Un solo quiote puede dar de 550 a 2500 plantas. Ésta es otra técnica de reproducción de la planta.',
            subtitle = '#SOMOSMEZCALMONTEALBAN';
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p style={{color: '#FFFFFF', fontWeight: 'lighter' }} className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                <br/>
                                <div className="thumbnail">
                                    <img className="w-60" src={IM4} alt="About Images" />
                                </div>
                                <br />
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                <p style={{marginBottom: '4px', color: '#FFFFFF', fontWeight: 'lighter'}} className="description" dangerouslySetInnerHTML={{ __html: description2 }}></p>
                                <p style={{marginBottom: '4px', color: '#FFFFFF', fontWeight: 'lighter'}} className="description" dangerouslySetInnerHTML={{ __html: description3 }}></p>
                                <p style={{marginBottom: '4px', color: '#FFFFFF', fontWeight: 'lighter'}} className="description" dangerouslySetInnerHTML={{ __html: description4 }}></p>
                                <br/>
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map((val, i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
