import React, { Component, Fragment } from "react";
import { BlogContentBotellas } from "./BlogContent";
import { Link } from 'react-router-dom';

class BLogList extends Component {
    render() {
        const PostList = BlogContentBotellas.slice(0, 10);
        return (
            <Fragment>
                <div className="row mt_dec--30">
                    {PostList.map((value, i) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" key={i}>
                            <div className="im_box">
                                <div className="thumbnail">
                                    <img className="w-100" src={`/assets/images/montealban/botellas/blog-${value.images}.png`} alt="Blog Images" />

                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                {value.category}
                                            </div>
                                            <h4 className="title">
                                                {value.title}
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            {/* <Link to="/Historia" className="rn-btn btn-opacity">Conocer Más...</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
}
export default BLogList;