import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Clip from '../../public/assets/images/montealban/Productos.mp4';

class Blog extends Component {
    render() {
        return (
            <React.Fragment>

                <Header homeLink="/" headertransparent="header--transparent" colorblack="color--black" logo="dark" logoname="logo.png" />
                <div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <video style={{ position: 'absolute', width: 'auto', height: 'auto', minWidth: '100%', minHeight: '100%' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                        <source src={Clip} type="video/mp4" />
                    </video>
                </div>
                
                <div style={{paddingTop: '40px'}} className="rn-blog-area ptb--30 bg_color--6">
                    <div className="container">
                        <div className="row pb--20">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">#SOMOSMONTEALBAN</span>
                                    <h3 style={{ color: 'white' }} className="title">¡CONOCE TODAS NUESTRAS PRESENTACIONES!</h3>
                                    <span style={{ fontSize: '16px' }} className="subtitle">"Nuestros productos nos distinguen en el mercado, por nuestro gran sabor totalmente mexicano"</span><br />
                                </div>
                            </div>
                        </div>
                        <BlogList />
                        <div className="row mt--10">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                {/* <Pagination /> */}
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}

                <PageHelmet pageTitle='Monte Alban || Productos' />
                <Breadcrumb title={'Productos Monte Alban'} />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Blog;
