import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiMail, FiPhone, FiPhoneCall } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import { Link } from 'react-router-dom';
import Clip from '../../public/assets/images/montealban/montealbanagave.mp4';
import Clip2 from '../../public/assets/images/montealban/usos.mp4';
import GoogleMapReact from 'google-map-react';
import { Uso, UsoPlanta } from './constants';
/*Imagenes importadas*/
import IM1 from '../../public/assets/images/montealban/Todas/IM1.png';
import IM2 from '../../public/assets/images/montealban/Todas/IM2.png';

/*Mapeo de imagenes*/
const listItemsUso = Uso.map((uso) =>
    <img src={IM1} />
);
const listItemsUsoPlanta = UsoPlanta.map((usoplanta) =>
    <li><p>{usoplanta}</p></li>
);

const SlideList = [
    {
        textPosition: 'text-left',
        category: '#SOMOSMONTEALBAN',
        description: '',
        buttonText: 'NUESTRA HISTORIA',
        buttonLink: '/historia'
    }
]
const PortfolioLanding = () => {
    let title = 'Contenido de productos',
        description = '';
    const PostList = BlogContent.slice(0, 2);
    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Mezcal Monte Alban" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

            {/* Start Slider Area   */}
            <div id="inicio" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div /* style={{ borderRadius: '20px' }} */ className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span style={{ color: '#daa520', fontSize: '19px', fontWeight: 'bolder' }}>{value.category}</span> : ''}
                                            <h1 className="title">BIENVENIDOS
                                                <h1 style={{ color: '#212121', WebkitTextStroke: '1px #cc9900' }}><span style={{ color: '#FF0000' }}>M</span>ONTE <span style={{ color: '#FF0000' }}>A</span>LBAN</h1>
                                                <TextLoop>
                                                    <span style={{ color: '#FF0000' }}> #REGIONAL</span>
                                                    <span style={{ color: '#FF0000' }}> #OAXACA</span>
                                                    <span style={{ color: '#FF0000' }}> 100% AGAVE</span>
                                                </TextLoop>{" "}
                                            </h1>
                                            <br /><br />
                                            {/* {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn mt--30"><a style={{ backgroundColor: '#191919', color: '#FFFFFF' }} className="btn-default btn-border" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start Service Area  */}
            <div id="servicio" className="fix" /* style={{backgroundColor: '#FFFFFF'}} */>
                <div className="service-area creative-service-wrapper ptb--20 bg_color--5" > {/* pb--120 */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--20 mb_sm--0">
                                    <span className="subtitle">LA NATURALEZA DE NUESTRO MEZCAL</span>
                                    <h2 className="title">EL AUTÉNTICO SABOR MEXICANO</h2>
                                    <p style={{color: '#FFFFFF'}}>
                                        "Bebe el agua a chorros y el mezcal a sorbos, ya que cada sorbo de mezcal cuenta una historia diferente." <br/>¿Cúal es la tuya...?  
                                    </p>

                                    <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                    
                                    <h2 className="description" style={{ color: '#FFFFFF', fontWeight: 'bolder' }}>
                                    <span style={{color: '#FFFFFF'}}>¡</span><span style={{ color: '#FF0000' }}>M</span><span style={{color: '#FFFFFF'}}>ezcal</span> <span style={{ color: '#FF0000' }}>M</span><span style={{color: '#FFFFFF'}}>onte</span> <span style={{ color: '#FF0000' }}>A</span><span style={{color: '#FFFFFF'}}>lban Escribiendo Historias!</span>
                                    </h2>
                                    {/* Seccion del video */}

                                </div>

                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="btn-default" href="/historia"><span>Conocer más</span></a>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="row creative-service">
                            <div className="col-lg-12">
                                 <ServiceList item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            <div style={{ position: 'relative', height: 'auto', width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <video style={{ marginTop: '-50px', marginBottom: '-50px', width: '100vw', height: 'auto', minHeight: '100%' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                    <source src={Clip} type="video/mp4" />
                </video>
            </div>
            <br />

            {/* Start Portfolio Area */}
            <div id="productos" className="fix">
                <div className="portfolio-area ptb--10 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--10 mb_sm--0">
                                        {/* <span className="subtitle">#</span> */}
                                        <span style={{ fontSize: '16px' }} className="subtitle">"Nuestros productos nos distinguen en el mercado, por nuestro gran sabor totalmente mexicano"</span><br />
                                        <h3 className="title">CONOCE NUESTROS PRODUCTOS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-6 col-md-6 col-sm-6 col-12" item="10" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <br />
                                    <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                    <div className="view-more-btn mt--30 mt_sm--30 text-center">
                                        <a className="btn-default" href="/productos"><span>Ver Todos los Productos</span></a>
                                    </div>
                                    <br/>
                                    <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            {/* <div id="acerca" className="fix">
                <div className="rn-blog-area ptb--10 pb--25 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <span className="subtitle" style={{ fontSize: '20px' }}>¿Quieres Saber Más?</span>
                                    <h2 className="title">EL Hombre y los Agaves</h2>
                                    <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                    <div style={{ height: '250px', paddingBottom: '20px' }}>
                                        <div style={{ height: '250px', overflowX: 'hidden' }}>
                                            <p style={{ color: 'white' }} className="description">
                                                Hace diez mil años comenzó en Mesoamérica la relación del hombre con el maguey, desde que sus antiguos pobladores vivían en forma nómada, hasta su asentamiento en lugares donde, al igual que otros cultivos, domesticaron el agave. A partir de entonces esta planta fue elemento importante de prácticas propiciatorias de lluvia, recolección de cosechas, ceremonias de algunos dioses y ritos de iniciación.
                                                <br />
                                                La milenaria historia cultural que conforma el México actual radica en su diversidad geográfica y, por ende, en el cultivo del maguey o agave-del griego admirabilis, por su sobrevivencia con escasos niveles de agua. Las zonas semiáridas del territorio nacional están pobladas de estos gigantescos erizos vegetales, donde existen más de un centenar de variedades, cuyos nombres y usos varían de región a región: zapupe, amole, ixtle, pita, magueyón, zapalote, etcétera.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="thumbnail" style={{ maxWidth: '100%', textAlign: 'center' }}>
                            <div style={{ margin: '0px auto', width: 'fit-content' }}>
                                <img className="w-100" src={IM1} alt="Blog Images" style={{ height: '500px', width: '100%' }} />
                            </div>
                            <div style={{ height: '230px', paddingTop: '15px', paddingBottom: '15px' }}>
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                <div style={{ height: '200px', overflowX: 'hidden' }}>
                                    <p style={{ color: 'white' }} className="description">
                                        Cuenta la leyenda que en la región mixteca la princesa Mayahuel descubrió en el Popoconaltepec -Monte de la Espuma- la manera de extraer del corazón de la planta llamada meztl un líquido delicioso y enervante. A la llegada de los españoles, el meztl fue rebautizado como maguey.
                                        <hr />
                                        El meztl o maguey pertenece a la familia de las agavaceas. Sus hojas grandes, tiesas, radicales, carnosas, de bordes espinosos y con una descollante púa en la punta, están organizadas a modo de roseta piramidal y formadas por un tejido filamentoso con cuya savia se produce una bebida incitadora de alegría y agradable somnolencia.
                                        <hr />
                                        Para crecer y madurar adecuadamente, los plantíos del agave mezcalero, espadín o angustifolia haw, requieren de limpieza y cuidado, así como mantenerse libres de maleza, pues fácilmente se plagan o enronchan, aunque lo maravilloso de este vegetal es que hasta sus plagas son aprovechables, según observaron los frailes evangelizadores. Es precisamente un gusano (hypopta agavis) el que ha dado sabor especial y fama al mezcal de Oaxaca.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="thumbnail" style={{ maxWidth: '100%', textAlign: 'center' }}>
                            <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                            <div style={{ margin: '0px auto', width: 'fit-content' }}>
                                <img className="w-100" src={IM2} alt="Blog Images" style={{ height: '500px', width: '100%' }} />
                            </div>
                            <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                <div style={{ height: '200px', overflowX: 'hidden' }} >
                                    <p style={{ color: 'white' }} className="description">
                                        A pesar de la apariencia árida y desolada del paisaje donde crece el agave, y de la fácil obtención de otros aguardientes, los mezcaleros oaxaqueños siempre han defendido lo suyo basados en la pureza ritual de su elaboración, transmitida de abuelos a padres y de padres a hijos.
                                        <hr style={{ borderWidth: '1px', height: '1px', color: 'white', width: '50%' }} />
                                        En la actualidad esta producción local se ha modificado, el desarrollo económico del país ha alentado su fabricación no sólo para el mercado regional, sino nacional e internacional.
                                    </p>
                                </div>
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                <a className="btn-default" style={{ backgroundColor: '#FF0000', border: '#FF0000 1px', marginTop: '20px' }} href="/historia">
                                    <p>CONOCE NUESTRA HISTORIA</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Blog Area */}

            {/* Start About Area */}
            <div style={{marginTop: '-30px'}} className="ptb--50 bg_color--5">
                <div className="section-title text-center mb--10 mb_sm--0">
                    <span style={{ fontSize: '16px', marginBottom: '15px' }} className="subtitle">#MezcalRegionalDeOaxaca</span><br />
                    <h3 className="title">¡ADQUIERE YA NUESTROS PRODUCTOS!</h3>

                </div>
                <br/>
                <div className="container">
                    <div className="row row--100" style={{ justifyContent: 'center' }}>
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/montealban/Todas/IM3.png" alt="About Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}

            {/* Start COntact Area */}
            <div id="contacto" className="fix">
                <div className="rn-contact-area ptb--30 pb--10 bg_color--8">
                    <ContactThree contactImages="/assets/images/montealban/Todas/atencion-cliente.png" contactTitle="Aqui estoy." />
                </div>
            </div>
            {/* End COntact Area */}

            {/* Start Blog Area */}
            <div className="fix">
                <div className="rn-blog-area ptb--20 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    {/* <span style={{ fontSize: '18px' }} className="subtitle">DESTILADORA ENVASADORA MONTE ALBAN S.A. DE C.V.</span> */}
                                    {/* <h5 className="title">DESTILADORA ENVASADORA MONTE ALBAN S.A. DE C.V.</h5> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default PortfolioLanding;
