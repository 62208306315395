import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
/* import { FaReact, FaSass } from "react-icons/fa"; */
import { FiChevronUp } from "react-icons/fi";
//import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton, } from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiSlack, FiTruck, FiSend } from "react-icons/fi";
/* import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi"; */
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import Clip from '../../public/assets/images/montealban/montealbanclip.mp4';
import PageHelmet from "../component/common/Helmet";
import IM13 from '../../public/assets/images/montealban/Todas/IM13y14.png';

/* const featureList = [
    {
        icon: <FaReact />,
        title: 'Latest React 16.8+',
        subtitle: 'We used latest react vertion ^16.8.6.Its a awesome design made by react.'
    },
    {
        icon: <FiSmartphone />,
        title: 'Perfect Responsive',
        subtitle: 'Our Template is full Perfect for all device. You can visit our template all device easily.'
    },
    {
        icon: <FiCode />,
        title: 'Well Documented Codes',
        subtitle: 'The Imroz code is awesome well documented code. And Its customization is very easily'
    },
    {
        icon: <FaSass />,
        title: 'Sass Available',
        subtitle: 'The tamplate has Sass available for css. You can Change css by sass'
    },
    {
        icon: <FiDownload />,
        title: 'Fast Loading Speed',
        subtitle: 'Imroz is faster loading speed.Imroz create your theme so much faster '
    },
    {
        icon: <FiCommand />,
        title: 'Modern Design',
        subtitle: 'Imroz is a modern creatuve design for Creative Agency , Personal Portfolio etc....'
    },
    {
        icon: <FiHeadphones />,
        title: '24 Support System',
        subtitle: 'We are provide 24 hours support for all clients.You can purchase without hesitation.'
    },
    {
        icon: <FiBold />,
        title: 'Bootstrap Comfortable',
        subtitle: 'Bootstrap comfortable is available in Imroz. So layout changes is so much easily'
    }
] */

const singleDemo = [
    {
        url: 'dark-main-demo',
        imageUrl: 'IM7.png',
        title: 'Cultura Monte Alban',
        label: ''
    },
    {
        url: 'main-demo',
        imageUrl: 'IM21.png',
        title: 'Calendario de Cultivo',
        label: ''
    },
]

const singleDemo2 = [
    {
        url: 'dark-main-demo',
        imageUrl: 'IM6.png',
        title: 'Cultura Monte Alban',
        label: ''
    },
    {
        url: 'main-demo',
        imageUrl: 'IM5.png',
        title: 'Regiones del mezcal',
        label: ''
    },
]

const agencyDemo = [
    {
        url: 'main-demo',
        imageUrl: 'IM15.png',
        title: 'ENVASADO',
        label: ''
    },
    {
        url: 'creative-agency',
        imageUrl: 'IM16.png',
        title: 'COMERCIALIZACIÓN',
        label: ''
    },
]

const busenessDemo = [
    {
        url: 'business',
        imageUrl: 'IM9Y10.png',
        title: 'CORTE',
        label: ''
    },
    {
        url: 'startup',
        imageUrl: 'IM11Y12.png',
        title: 'RECOLECCIÓN',
        label: ''
    },

]

/* const portfolioList = [
    {
        url: 'personal-portfolio',
        imageUrl: 'personal-portfolio.png',
        title: 'Personal Portfolio',
        label: 'Hot'
    },
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        label: ''
    },
    {
        url: 'designer-portfolio',
        imageUrl: 'designer-portfolio.png',
        title: 'Designer Portfolio',
        label: ''
    },

    {
        url: 'creative-portfolio',
        imageUrl: 'creative-portfolio.png',
        title: 'Creative Portfolio',
        label: ''
    },
    {
        url: 'portfolio-home',
        imageUrl: 'portfolio-home.png',
        title: 'Minimal Portfolio',
        label: ''
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        label: 'Hot'
    },
]

const landingPage = [
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        label: ''
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        label: 'Hot'
    },
    {
        url: 'creative-landing',
        imageUrl: 'creative-landing.png',
        title: 'Creative Agency 02 — One Page',
        label: ''
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        label: 'Hot'
    },
    {
        url: 'home-particles',
        imageUrl: 'homeparticles.png',
        title: 'Home Particle — Landing Page',
        label: ''
    },
]

const newDemoList = [
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        label: ''
    },
    {
        url: 'dark-main-demo',
        imageUrl: 'dark-main-demo.png',
        title: 'Main Demo — Dark Version',
        label: ''
    },
    {
        url: 'corporate-business',
        imageUrl: 'corporate-business.png',
        title: 'Corporate Business',
        label: ''
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        label: 'Hot'
    },
    {
        url: 'designer-portfolio',
        imageUrl: 'designer-portfolio.png',
        title: 'Designer Portfolio',
        label: ''
    },
    {
        url: 'creative-portfolio',
        imageUrl: 'creative-portfolio.png',
        title: 'Creative Portfolio',
        label: ''
    },
    {
        url: 'creative-landing',
        imageUrl: 'creative-landing.png',
        title: 'Creative Agency 02 — One Page',
        label: ''
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        label: 'Hot'
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        label: 'Hot'
    },
]

const innerDemo = [
    {
        url: 'service',
        imageUrl: 'service.png',
        title: 'Service',
    },
    {
        url: 'service-details',
        imageUrl: 'service-details.png',
        title: 'Service Details',
    },
    {
        url: 'about',
        imageUrl: 'about.png',
        title: 'About',
    },
    {
        url: 'blog',
        imageUrl: 'blog-list.png',
        title: 'Blog List',
    },
    {
        url: 'blog-details',
        imageUrl: 'blog-details.png',
        title: 'Blog Details',
    },
    {
        url: 'portfolio',
        imageUrl: 'portfolio.png',
        title: 'Portfolio',
    },
    {
        url: 'portfolio-details',
        imageUrl: 'portfolio-details.png',
        title: 'Portfolio Details',
    },

    {
        url: 'video-popup',
        imageUrl: 'video-popup.png',
        title: 'Video popup',
    },
    {
        url: 'contact',
        imageUrl: 'contact.png',
        title: 'Contact',
    },

]
 */
const ServiceList = [
    {
        icon: <FiSlack />,
        title: 'CULTIVO & COSECHA',
        description: 'Optimizamos nuestros procesos con el fin de otorgar un Mezcal de Calidad, en buena condición y lograr un excelente sabor.'
    },
    {
        icon: <FiTruck />,
        title: 'ENVASADO & COMERCIALIZACIÓN',
        description: 'Hacemos las mejores presentaciones para que nuestro mezcal se mantenga en un estado excelente para su consumo, además de una presentación digna para el consumidor.'
    },
    {
        icon: <FiSend />,
        title: 'CORTE & RECOLECCIÓN',
        description: 'Contamos con la maquinaria necesaria y las herramientas perfectas para obtener de manera limpia nuestro material.'
    },

]

class Demo extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return (
            <div className="active-dark">
                <PageHelmet pageTitle='Monte Alban || Cultura' />
                {/* Start Banner Area  */}
                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

                {/* Start Slider Area   */}
                <div className="pv-slider-area slider-wrapper">
                    <div className="slider-activation">

                        {/* Start Single Slide */}
                        <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--34" data-black-overlay="9">
                            <div style={{ opacity: '0.3', position: 'absolute', height: '100%', width: '100vw', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <video style={{ position: 'absolute', width: 'auto', height: '100%', minWidth: '100%', minHeight: '100%' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                                    <source src={Clip} type="video/mp4" />
                                </video>
                            </div>
                            <div className="container position-relative">

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h1 className="title theme-gradient">Cultura del Mezcal</h1>
                                        </div>
                                    </div>
                                </div>

                                {/* Start Service Area */}
                                <div className="service-wrapper service-white">
                                    <div className="row row--25">
                                        {ServiceList.map((val, i) => (
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                <div className="service service__style--1">
                                                    <div className="icon">
                                                        {val.icon}
                                                    </div>
                                                    <div className="content">
                                                        <h4 style={{ color: 'white' }} className="title">{val.title}</h4>
                                                        <p >{val.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* End Service Area */}

                            </div>
                        </div>
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* End Banner Area  */}

                {/* Start Preview Main Wrapper */}
                <div className="main-wrapper">

                    {/* Start Main Demo Area  */}
                    <div id="demo" className="home-demo-area bg_color--1 ptb--50">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30">
                                        <h2 className="title theme-gradient">MONTE ALBAN</h2>
                                        <p style={{ color: 'white' }}>
                                            Es un hecho que la producción y consumo de alcohol siempre ha estado presente en las sociedades de todos los tiempos, y en muchas de ellas ha sido valorado como alimento nutritivo y como medicina para aliviar el dolor y tratar enfermedades específicas.
                                            Esta circunstancia, aunada a la cultura de autosuficiencia de las comunidades campesinas tradicionales, transmitida de padres a hijos, propició que en los lugares donde abundan los agaves mezcaleros como Oaxaca, Guerrero, Durango, San Luis Potosí y Zacatecas se preservara su producción. Hoy, después de trescientos años de vicisitudes, la denominación de origen otorgada recientemente reconoce a estas mismas regiones la exclusividad de siembra, cultivo de la planta y extracción de la bebida, en especial al estado de Oaxaca.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <Tabs>
                                {/* <div className="row text-center">
                                    <div className="col-lg-12">
                                        <div className="tablist-inner">
                                            <TabList className="pv-tab-button text-center mt--30">
                                                <Tab><span>CULTURA MONTE ALBAN</span></Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                </div> */}

                                <TabPanel className="row">
                                    {singleDemo2.map((value, index) => (
                                        <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" >
                                                    <img src={`/assets/images/montealban/Todas/${value.imageUrl}`} />
                                                    <h3 className="title">{value.title}{value.label ? <span className="label-new"><span>{value.label}</span></span> : ''}</h3>
                                                    {/* <p className="info">{value.description}</p> */}
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                    <hr style={{ width: '100%', height: '2px', marginTop: '30px', backgroundColor: 'red' }} />
                                </TabPanel>
                            </Tabs>


                            <Tabs>
                                <div className="row text-center">
                                    <div className="col-lg-12">
                                        <div className="tablist-inner">
                                            <TabList className="pv-tab-button text-center mt--30">
                                                <Tab><span>CULTIVO & COSECHA</span></Tab>
                                                <Tab><span>CORTE & RECOLECCIÓN</span></Tab>
                                                <Tab><span>ENVASADO & COMERCIALIZACIÓN</span></Tab>
                                                {/* <Tab><span>Portfolio</span></Tab>
                                                <Tab><span>Landing</span></Tab> */}
                                            </TabList>
                                        </div>
                                    </div>
                                </div>

                                <TabPanel className="row">
                                    {singleDemo.map((value, index) => (
                                        <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" >
                                                    <img src={`/assets/images/montealban/Todas/${value.imageUrl}`} />
                                                    <h3 className="title">{value.title}{value.label ? <span className="label-new"><span>{value.label}</span></span> : ''}</h3>
                                                    {/* <p className="info">{value.description}</p> */}
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                    <hr style={{ width: '100%', height: '2px', marginTop: '30px', backgroundColor: 'red' }} />
                                    <p style={{ color: 'white', textAlign: 'center' }}>
                                        Por generaciones los agaves mezcaleros se han ido seleccionando a partir de plantas silvestres, tomando en cuenta forma, tamaño y sabor de las "piñas". La domesticación y mejoramiento del maguey espadín se ha logrado mediante la creación de viveros de "hijuelos", "magueyitos" o "mecuates" provenientes del "quiote" de la planta o de los rizomas alrededor de la misma.
                                        <br />
                                        La siembra del agave se realiza en los terrenos más diversos. Donde la inclinación es acentuada se siembran de mil a dos mil quinientos magueyes por hectárea y en lugares pedregosos un poco menos. En superficies difíciles de limpiar, no se siembran más de cuatrocientas plantas. Llegan a pasar hasta diez años o más para que la planta alcance la madurez deseada y se pueda tumbar y procesar. Su cultivo está dentro de lo que hoy se conoce como agricultura orgánica, pues en su crecimiento y desarrollo no se utilizan ni pesticidas ni fertilizantes, crece en forma natural. Es indispensable, sin embargo, limpiarlo cada año de hierbas y dejar su espacio vital libre y desde luego, tiempo, mucho tiempo, cuidando que no se plague.
                                    </p>
                                </TabPanel>

                                <TabPanel className="row">
                                    {busenessDemo.map((value, index) => (
                                        <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" >
                                                    <img src={`/assets/images/montealban/Todas/${value.imageUrl}`} alt="MonteAlban" />
                                                    <h3 className="title">{value.title}{value.label ? <span className="label-new"><span>{value.label}</span></span> : ''}</h3>
                                                    {/* <p className="info">{value.description}</p> */}
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                    <hr style={{ width: '100%', height: '2px', marginTop: '30px', backgroundColor: 'red' }} />
                                    <p style={{ color: 'white', textAlign: 'center' }}>Es un hecho que la producción y consumo de alcohol siempre ha estado presente en las sociedades de todos los tiempos, y en muchas de ellas ha sido valorado como alimento nutritivo y como medicina para aliviar el dolor y tratar enfermedades específicas.
                                        Esta circunstancia, aunada a la cultura de autosuficiencia de las comunidades campesinas tradicionales, transmitida de padres a hijos, propició que en los lugares donde abundan los agaves mezcaleros como Oaxaca, Guerrero, Durango, San Luis Potosí y Zacatecas se preservara su producción. Hoy, después de trescientos años de vicisitudes, la denominación de origen otorgada recientemente reconoce a estas mismas regiones la exclusividad de siembra, cultivo de la planta y extracción de la bebida, en especial al estado de Oaxaca.
                                    </p>
                                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo" >
                                            <a target="_blank" rel="noopener noreferrer" >
                                                <img src={IM13} alt="MonteAlban" />
                                                <h3 className="title">Monte Alban</h3>
                                                {/* <p className="info">{value.description}</p> */}
                                            </a>
                                        </div>
                                    </div>

                                </TabPanel>

                                <TabPanel className="row">
                                    {agencyDemo.map((value, index) => (
                                        <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" >
                                                    <img src={`/assets/images/montealban/Todas/${value.imageUrl}`} alt="MonteAlban" />
                                                    <h3 className="title">{value.title}{value.label ? <span className="label-new"><span>{value.label}</span></span> : ''}</h3>
                                                    {/* <p className="info">{value.description}</p> */}
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                    <hr style={{ width: '100%', height: '2px', marginTop: '30px', backgroundColor: 'red' }} />
                                    <p style={{ color: 'white', textAlign: 'center' }}>
                                        Desde la siembra hasta la cosecha de la planta, es el propietario o cultivador el encargado de su cuidado. Por lo general es una persona conocedora del cultivo, quien identifica cuando el agave está "en su punto" para ser cortado y obtener la "piña" o corazón del maguey. Una vez cortadas las bolas esferoidales, que a veces rebasan los cien kilogramos, son trasladadas al "palenque" para su procesamiento en camiones de carga o en carretones jalados por bueyes.
                                    </p>
                                </TabPanel>

                                {/* <TabPanel className="row">
                                    {portfolioList.map((value, index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="MonteAlban" />
                                                    <h3 className="title">{value.title}{value.label ? <span className="label-new"><span>{value.label}</span></span> : ''}</h3>
                                                    <p className="info">{value.description}</p>
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {landingPage.map((value, index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="MonteAlban" />
                                                    <h3 className="title">{value.title}{value.label ? <span className="label-new"><span>{value.label}</span></span> : ''}</h3>
                                                    <p className="info">{value.description}</p>
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel> */}
                            </Tabs>
                        </div>
                    </div>
                    {/* End Main Demo Area  */}

                    {/* Start Main Demo  */}
                    {/* <div className="home-demo-area bg_color--5 ptb--120">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h2 className="title theme-gradient">Inner Pages</h2>
                                        <p className="description">Choose one of styles or cutomize easily your site following your ideas</p>
                                    </div>
                                </div>
                            </div>
                             <div className="row">
                                {innerDemo.map((value, index) => (
                                    // Start Single Demo
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                <img src={`/assets/images/preview/${value.imageUrl}`} alt="MonteAlban" />
                                                <h3 className="title">{value.title}</h3>
                                            </a>
                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}
                            </div> 
                        </div>
                    </div> */}
                    {/* End Main Demo  */}

                    {/* Start Feature Area  */}
                    {/* <div id="feature" className="service-area bg_color--1 ptb--120">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30">
                                        <span className="subtitle">Our Imroz Feature</span>
                                        <h2 className="title">Awesome Feature</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row service-main-wrapper">
                                {featureList.map((value, i) => (
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                {value.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{value.title}</h3>
                                                <p className="subtitle">{value.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                    {/* End Feature Area  */}

                    {/* Start Faq Area */}
                    {/* <div id="faq" className="pv-feaq-area bg_color--5 ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-title text-left pb--30">
                                        <span className="subtitle theme-gradient">Check out our FAQ section to see if we can help.</span>
                                        <h2 className="title">Do you have any Question</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="faq-area">
                                        <Accordion className="accodion-style--1" preExpanded={'0'}>
                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        What is Imroz ? How does it work?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>Welcome to Imroz React Creative Agency, React Portfolio and Corporate Multi Purpose Template Built With React JS. NO jQuery!. It works too much faster loading speed and you can works too much comfortability.Imroz create your website so much faster, use to use and Well Documented Codes for your customization.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        How can I run Imroz react template?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>You can run Imroz easily.
                                                        First You'll need to have node and npm on your machine.
                                                        So Please open your command prompt then check your node -v and npm -v Version.
                                                        Goes To Your your command prompt: then
                                                        First: <strong className="theme-color">npm install</strong></p>
                                                    <p>At Last: <strong className="theme-color">npm run start</strong>. By the following way you can be run your project easily.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>


                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        How can I get the customer support?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        After purchasing the product  need you any support you can be share with us with sending mail to <a className="theme-gradient" href="mailto:rainbowit10@gmail.com">rainbowit10@gmail.com</a>.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Can I get update regularly and For how long do I get updates?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        Yes, We will get update the Imroz. And you can get it any time. Next time we will comes with more feature.
                                                        You can be get update for unlimited times. Our dedicated team works for update.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Can I change any component as I like?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        Yes, You can change any component as you like. And By the way you can build your website which you are choose.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Can I build a complete project with this template?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        Yes, Why not. You can build a project and complete website as you are like. More component are available include in this templete. And you can be use it following documentation.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* Start Faq Area */}


                    {/* Start Footer Area  */}
                    {/* <footer className="pv-callto-action call-to-action-wrapper text-white-wrapper call-to-action ptb--120 bg_image bg_image--34" data-black-overlay="9">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner text-center">
                                        <span>Purchase The Imroz and Make Your Site super faster and easy.</span>
                                        <h2 className="mt--20 theme-gradient font-700">Let's go to Purchase</h2>
                                        <div className="footer-btn mt--35">
                                            <a className="btn-default" target="_blank" href="https://themeforest.net/checkout/from_item/31405042?license=regular"><span>Purchase Now</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                    <FooterTwo />
                    {/* End Footer Area  */}

                </div>
                {/* End Preview Main Wrapper */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}
export default Demo;
