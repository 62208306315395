import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

class TabsTwo extends Component {
    render() {
        let
            tab1 = "Grados",
            tab2 = "Alimenticio",
            tab3 = "Calendario",
            tab4 = "Cultura";
        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">1 Litro Botella Larga / Compacta</h6>
                                                    <ProgressBar now={100} />
                                                    <span className="label">100%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">750ML Botella Grabada / Larga</h6>
                                                    <ProgressBar now={75} />
                                                    <span className="label">75%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">500ML Botella Larga</h6>
                                                    <ProgressBar now={50} />
                                                    <span className="label">50%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">375ML Botella Compacta</h6>
                                                    <ProgressBar now={37} />
                                                    <span className="label">37%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">250ML Botella Larga</h6>
                                                    <ProgressBar now={25} />
                                                    <span className="label">25%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">50ML Botella Compacta</h6>
                                                    <ProgressBar now={5} />
                                                    <span className="label">5%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div>

                                            </div>
                                            <ul>
                                                <li>
                                                    <a href="/service">1 año <span>- </span></a>  -
                                                </li>
                                                <li>
                                                    <a href="/service">Preparacion del terreno<span></span></a>  -
                                                </li>
                                                <li>
                                                    <a href="/service">#  <span>- </span></a> -
                                                </li>
                                                <li>
                                                    <a href="/service"># <span>- </span></a>  -
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Front-end <span> - </span></a>  -
                                                </li>
                                                <li>
                                                    <a href="/service">Front-end <span> - </span></a>  -
                                                </li>
                                                <li>
                                                    <a href="/service">Software <span> -  </span></a> -
                                                </li>
                                                <li>
                                                    <a href="/service">Software <span> -  </span></a> -
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">  <span> -  </span></a>
                                                </li>
                                                <li>
                                                    <a href="/service"> <span> - </span></a> -
                                                </li>
                                                <li>
                                                    <a href="/service"> <span> -  </span></a>
                                                </li>
                                                <li>
                                                    <a href="/service"> <span> -  </span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}
export default TabsTwo;