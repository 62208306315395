import React, { Component } from "react";
import { FiSettings, FiSun, FiSmile } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiSun />,
        title: 'Uso del Agave',
        description: 'Nuestros procesos con el agave son únicos y perfeccionados para lograr un sabor inigualable.'
    },
    {
        icon: <FiSettings />,
        title: '¿Cómo lo hacemos?',
        description: 'Tenemos las herramientas necesarias para lograr que nuestro mezcal sea de su total agrado.'
    },
    {
        icon: <FiSmile />,
        title: 'Nuestra producción',
        description: 'Nosotros nos encargamos de dar el mejor trato a nuestros recursos y entregar productos de calidad.'
    },
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;