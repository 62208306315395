const BlogContent = [
    {
        images: 'mapa',
        title: '',
        category: ''
    },
    {
        images: 'codice',
        title: '',
        category: ''
    },
    {
        images: '03',
        title: '',
        category: ''
    },
    {
        images: '04',
        title: '',
        category: ''
    },
    {
        images: '05',
        title: '',
        category: ''
    },
    {
        images: '06',
        title: '',
        category: ''
    },
    {
        images: '07',
        title: '',
        category: ''
    },
]

export default BlogContent;

export const BlogContentBotellas = [
    {
        images: '1L',
        title: '',
        category: ''
    },
    {
        images: '1LC',
        title: '',
        category: ''
    },
    {
        images: '750G',
        title: '',
        category: ''
    },
    {
        images: '750N',
        title: '',
        category: ''
    },
    {
        images: '500M',
        title: '',
        category: ''
    },
    /* {
        images: '500ml',
        title: '',
        category: ''
    }, */
    /* {
        images: '375M',
        title: '',
        category: ''
    }, */
    {
        images: '375ml',
        title: '',
        category: ''
    },
    {
        images: '250ml',
        title: '',
        category: ''
    },
    {
        images: '50ml',
        title: '',
        category: ''
    },
]