import React, { Component } from "react";
import { Link } from 'react-router-dom';
import im1 from '../../../public/assets/images/montealban/botellas/blog-1L.png';
import im2 from '../../../public/assets/images/montealban/botellas/blog-1LC.png';
import im3 from '../../../public/assets/images/montealban/botellas/blog-750G.png';
import im4 from '../../../public/assets/images/montealban/botellas/blog-750N.png';
import im5 from '../../../public/assets/images/montealban/botellas/blog-500ml.png';
import im6 from '../../../public/assets/images/montealban/botellas/blog-500M.png';
import im7 from '../../../public/assets/images/montealban/botellas/blog-375ml.png';
import im8 from '../../../public/assets/images/montealban/botellas/blog-375M.png';
import im9 from '../../../public/assets/images/montealban/botellas/blog-250ml.png';
import im10 from '../../../public/assets/images/montealban/botellas/blog-50ml.png';

const image = <img src={im1} alt="Mezcal Monte Alban" />;
const image2 = <img src={im2} alt="Mezcal Monte Alban" />;
const image3 = <img src={im3} alt="Mezcal Monte Alban" />;
const image4 = <img src={im4} alt="Mezcal Monte Alban" />;
const image5 = <img src={im5} alt="Mezcal Monte Alban" />;
const image6 = <img src={im6} alt="Mezcal Monte Alban" />;
const image7 = <img src={im7} alt="Mezcal Monte Alban" />;
const image8 = <img src={im8} alt="Mezcal Monte Alban" />;
const image9 = <img src={im9} alt="Mezcal Monte Alban" />;
const image10 = <img src={im10} alt="Mezcal Monte Alban" />;

const PortfolioListContent = [
    {
        image: image,
        category: '',
        title: 'Botella Larga de 1L',
        description: ''
    },
    {
        image: image2,
        category: '',
        title: 'Botella Compacta de 1L',
        description: ''
    },
    {
        image: image3,
        category: '',
        title: 'Botella Grabada de 750ml',
        description: ''
    },
    {
        image: image4,
        category: '',
        title: 'Botella Normal de 750ml',
        description: ''
    },
    /* {
        image: image5,
        category: '',
        title: 'Botella Larga de 500ml',
        description: 'Nuestra nueva presentación de 500ml.'
    }, */
    {
        image: image6,
        category: '',
        title: 'Botella Compacta de 500ml',
        description: ''
    },
    {
        image: image7,
        category: '',
        title: 'Botella Compacta de 375ml',
        description: ''
    },
    /* {
        image: image8,
        category: '',
        title: 'Botella Compacta de 375ml',
        description: 'Nuestra nueva presentación de 375ml.'
    }, */
    {
        image: image9,
        category: '',
        title: 'Botella Compacta de 250ml',
        description: ''
    },
    {
        image: image10,
        category: '',
        title: 'Botella Compacta de 50ml',
        description: ''
    }
]

class PortfolioList extends Component {
    render() {
        const { column, styevariation } = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    {value.image}
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            {value.category}
                                        </div>
                                        {/* <h4 className="title">{value.title}</h4> */}
                                    </div>
                                    <div className="portfolio_hover">
                                        <p style={{textAlign: 'center'}}>{value.description}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;