import React, { Component } from "react";
/* import FormStyle from './FormStyle'; */
import GoogleMapReact from 'google-map-react';
import { FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import Marker from "./Marker";
import './Marker.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
// 19.402256500609045, -98.92587261660051
const points = [
    { id: 1, title: "Round Pond", lat: 19.402256500609045, lng: -98.92587261660051 },
];

class ContactThree extends Component {

    static defaultProps = {
        center: {
            lat: 19.402256500609045,
            lng: -98.92587261660051
        },
        zoom: 18
    };

    render() {
        return (
            <div className="contact-form--1">
                <div style={{ width: '100%', textAlign: 'center', alignItems: 'center' }}>
                    <div className="row ">
                        <div className="col-lg-5 order-1 order-lg-1">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img style={{ height: 'auto', width: 'auto' }} src={`${this.props.contactImages}`} alt="montealban" />
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-2" style={{ justifyContent: 'center' }}>
                            <div className="section-title mb--20" style={{ justifyContent: 'center' }}>
                                <h4 style={{ textAlign: 'center' }} className="title">¿Cómo podemos ayudarte?</h4>
                                <span style={{ textAlign: 'center', fontSize: '16px' }} className="subtitle">¡Nos pondremos en contacto contigo lo más pronto posible!</span>
                                <div style={{ textAlign: 'center' }} className="im_address mt--5">
                                    <span style={{ color: 'white', textAlign: 'center' }}><FiPhoneCall />  <FaWhatsapp /></span>
                                    <a style={{ fontSize: '16px', textAlign: 'center', color: 'white' }} className="link im-hover" href="tel:+52 (55)65555712<">+52 (55)6555-5712</a>
                                </div>
                                <div style={{ textAlign: 'center' }} className="im_address mt--2">
                                    <span style={{ color: 'white', textAlign: 'center' }}><FiMail /></span>
                                    <a style={{ fontSize: '16px', textAlign: 'center', color: 'white' }} className="link im-hover" href="mailto:example@gmail.com">mezcalmontealban@gmail.com</a>
                                </div>
                                <div className="im_address mt--2">
                                    <a style={{ fontSize: '14px', textAlign: 'center', color: 'white' }} className="link im-hover" href="https://goo.gl/maps/Z69ANTqLxKUNafpE6">
                                        <FiMapPin />   Francisco I. Madero No. 21, Col. Revolución, C.P. 56390, Chicoloapan de Juárez, Estado de México
                                    </a>
                                </div>
                                <div className="im_address" style={{ borderRadius: '20px', height: '180px', width: '100%', marginTop: '8px' }}>

                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyBVlWIaY0yOCvp3bjNYsbvObyUL4HXW4xQ" }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}
                                        yesIWantToUseGoogleMapApiInternals
                                    >
                                        
                                        <AnyReactComponent
                                            lat={19.402256500609045}
                                            lng={-98.92587261660051}
                                            text=""
                                        />

                                        {points.map(({ lat, lng, id, title }) => {
                                            return (
                                                <Marker key={id} lat={lat} lng={lng} text='Mezcal MonteAlban' tooltip={title} />
                                            );
                                        })}

                                    </GoogleMapReact>
                                </div>
                            </div>
                            {/* <FormStyle /> */}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;