import React, { Component } from "react";
import { FiSend } from "react-icons/fi";
import { Link } from 'react-router-dom';
import IM3 from '../../../../public/assets/images/montealban/Todas/blog-codice.png';
import IM20 from '../../../../public/assets/images/montealban/Todas/IM20.png';

class About extends Component {
    render() {
        let title = 'USO DEL MAGUEY',
            description = 'Actualmente, la explotación del maguey se enfoca primordialmente a la elaboración de tequilas y mezcales, aunque es también común su uso en la elaboración de barbacoas y mixiotes. El maguey es también una planta que en sí misma entraña beneficios para la agricultura: se le utiliza para delimitar parcelas y terrenos.',
            description2 = 'Asimismo, es fundamental para las terrazas de cultivo, las parcelas que se sitúan en las laderas, pues permite la retención del suelo y previene la erosión.';

        let title2 = 'USO DEL AGAVE',
            description3 = 'Para la elaboración del mezcal es necesario el tallo y la base de las hojas del agave, a estas se les denomina como Piña o Cabeza, con el simple fin de obtener una bebida alcohólica cuyo proceso se convierte en parte artesanal, cultural y regional, preparado orgullosamente en México.',
            description4 = 'Las Piñas o cabeza del Agave se someten a un proceso de cocción de los azúcares extraídos de la planta y posteriormente sometidos a fermentación alcohólica, que al fin destilan para obtener el preciado mezcal.';
        return (
            <React.Fragment>
                <div className="about-wrapper" >
                    
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src={IM3} alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h3 className="title">{title}</h3>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn">
                                            <Link className="btn-transparent">#SOMOSMONTEALBAN</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <br />
                        <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                        <div style={{height: '10px'}}></div>
                    </div>

                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h3 className="title">{title2}</h3>
                                        <p className="description">{description3}</p>
                                        <p className="description">{description4}</p>
                                        <div className="purchase-btn">
                                            <Link className="btn-transparent">#SOMOSMONTEALBAN</Link>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <hr style={{ borderWidth: '1px', height: '1px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                            </div>
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src={IM20} alt="About Images" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;