import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock, FiUser, FiMessageCircle, FiHeart, FiSun } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
/* import Footer from "../component/footer/Footer"; */
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import fondo from '../../public/assets/images/montealban/montealban.png';
import Clip from '../../public/assets/images/montealban/Historia2.mp4';

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Monte Alban || Acerca De' />
                <HeaderThree headertransparent="header--transparent" colorblack="color--black" homeLink="/" logo="dark" />

                <div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <video style={{ position: 'absolute', width: 'auto', height: 'auto', minWidth: '100%', minHeight: '100%' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                        <source src={Clip} type="video/mp4" />
                    </video>
                </div>

                {/* Start Breadcrump Area */}
                <div style={{ backgroundColor: '#000000' }} className="rn-page-title-area" >
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--40" >
                                    <h2 className="title theme-gradient">MEZCAL MONTE ALBAN</h2>
                                    {/* <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />2022</li>
                                        <li><FiSun />#SOMOSMONTEALBAN</li>
                                        <li><FiMessageCircle />15 Comments</li>
                                        <li><FiHeart />Like</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div style={{ backgroundColor: '#000000' }} className="rn-blog-details pt--15 pb--40 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner" style={{ top: '-25px' }}>
                                        <p style={{ color: '#daa520', fontWeight: 'bolder', textAlign: 'center' }}>"Los propietarios de las fincas urbanas que flanquean la calzada, rentan accesorias abiertas para la venta del pulque y otros productos.
                                            Algunos cuartuchos albergan talleres de artesanos, tortillerías, fondas, tendajones de abarrotes y vinaterías."</p>
                                        <p className="mt--40" style={{ color: 'white',textAlign: 'justify' }}>
                                            <span style={{ color: '#daa520', fontWeight: 'bolder' }}>"Vinos y Licores Rubio"</span> era uno de aquellos estancos, ubicado en la calle de Balboa de la actual colonia Portales.
                                            La bebida que distinguió a ese comercio, iniciado en 1902, fue el mezcal Triunfo -quizás por la odisea de traer el líquido desde Oaxaca.
                                            <br />
                                            Entre <span style={{ color: '#daa520', fontWeight: 'bolder' }}>1910</span> y <span style={{ color: '#daa520', fontWeight: 'bolder' }}>1920</span> los disturbios revolucionarios se generalizan por todo el país.
                                            Durante aquella época, todavía entre huertos y milpas en torno a la calzada de Tlalpan, la familia Rubio, desde su negocio, ve desfilar a Emiliano Zapata con su ejército de indios vestidos de manta, huaraches, fusil en bandolera y sombrero de paja.
                                            Ya, a finales de los treinta, los combatientes son sustituidos en la marcha por vendedores de todo tipo de mercaderías.
                                        </p>

                                        <div className="d-flex flex-wrap mb--40">
                                            <div className="content">
                                                <img style={{ borderRadius: '15px' }} className="w-70" src="/assets/images/montealban/Todas/IM18.png" alt="BLog Images" />
                                            </div>
                                        </div>

                                        <p style={{ color: 'white',textAlign: 'center'  }}>
                                        Fue por aquel entonces cuando el joven Pedro Mercadé Pons llegó como proveedor de botellas a "Vinos y Licores Rubio". Años más tarde su modesta actividad de recolección de envases le permitió adquirir una "carcachita" y convertirse así en agente de ventas del establecimiento. Con sus cajas de licor y su juventud a cuestas recorrió en "el viejo Ford", desde Yucatán hasta Ensenada, restaurantes, cantinas, bares, tiendas de abarrotes, vinaterías y changarros de todo el país. No importaron distancias, climas, ni caminos de terracería para que este viajero de largo andar levantara pedidos de mezcal Triunfo y otras bebidas. Esta situación duró hasta mediados de los años cuarenta cuando se enteró que la vinatería estaba a punto de ser embargada. A ninguno de los Rubio le interesó rescatar el negocio, así que el noble comerciante decidió invertir todos sus ahorros en salvarlo. Sabía que no era lo mismo vender que producir, pero tenía claro que ambas actividades eran interdependientes. Su espíritu emprendedor lo llevó a incursionar en la fabricación y comercialización de vinos y licores, principalmente de mezcal.
                                        </p>

                                        <p style={{ color: 'white',textAlign: 'center'  }}>
                                            <span style={{ color: '#DB0B55', fontWeight: 'bolder' }}>"Vinos y Licores Rubio"</span> cambió de propietario, de domicilio -de Balboa se trasladó a Alhambra- y de razón social, ahora se llamará <span style={{ color: '#daa520', fontWeight: 'bolder' }}>"Vinos la Mexicana, S.A."</span>.
                                        </p>

                                        {/*<div className="d-flex flex-wrap">
                                            <div className="content">
                                                 <h4 className="title">Ordered & Unordered Lists.</h4> 

                                                <ul className="list-style">
                                                    <li style={{ color: '#daa520' }}></li>
                                                </ul>
                                                <hr />
                                                <h4 className="title">Ordered & Unordered Lists.</h4> 
                                                <ul className="list-style">
                                                    <li style={{ color: '#FFFFFF' }}></li>
                                                </ul>
                                            </div>
                                        </div>*/}
                                        <br/>
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                        <br/>
                                        <div className="d-flex flex-wrap mb--10 ">
                                            <div className="content">
                                                <img style={{ borderRadius: '15px' }} className="w-70" src="/assets/images/montealban/Todas/familia.png" alt="BLog Images" />
                                            </div>
                                        </div>
                                        <br/>
                                        <hr style={{ borderWidth: '1px', height: '2px', backgroundColor: 'white', color: 'white', width: '100%' }} />
                                        <p className="mb--0" style={{ textAlign: 'center', color: 'white' }}>En 1968 México fue anfitrión de "La Olimpiada de la Amistad", y con semejante compromiso internacional la ciudad se modernizó. Además de los recintos deportivos, se estrenó el Metro. La línea dos que comunica a la ciudad desde Tacuba hasta Taxqueña propició que la calzada de Tlalpan se convirtiera en vía rápida. La empresa "Vinos la Mexicana, S.A.". registrada entonces como "Mezcal Mitla, S.A.", quedó a unos pasos de la estación del Metro Ermita. La proyección de México en el ámbito internacional y la pericia que acumulaba don Pedro en el mercado de vinos y licores lo llevó a investigar la posibilidad de exportar su producto a otros países. Iniciar esta aventura tomó su tiempo. Seguro de la calidad del mezcal, en 1972 inició gestiones para cumplir con su primer contrato de exportación. Entre la intransigencia de los importadores que solicitaban el producto a granel y no envasado y la resistencia de las autoridades norteamericanas para aceptar el mezcal con gusano, los trámites duraron más de dos años. Finalmente, a principios de 1975 Mezcal Mitla, S.A., fue la primera empresa que exportó la bebida con gusano a los Estados Unidos, la marca elegida por sus clientes fue Mezcal Monte Albán: cuya aceptación indiscutible llevó al señor Mercadé a adoptar dicho nombre como razón social.</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <img style={{width: '100%', paddingTop: '40px', marginBottom: '-10px'}} src="/assets/images/montealban/Todas/todas.png" alt="Blog Images" /> */}
                </div>
                {/* End Blog Details */}

                {/* Start BLog Comment Form  */}
                {/* <div className="blog-comment-form pb--80 ptb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">¿Desea preguntarnos algo?</span>
                                        <h2 className="title">Escribenos a nuestro correo</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Nombre" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comentario"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>ENVIAR COMENTARIO</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;